var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-white my-4 vx-row w-full" }, [
    _c(
      "div",
      { staticClass: "pt-5 px-8" },
      [
        _c(
          "b-tabs",
          { staticClass: "m-0" },
          _vm._l(_vm.types, function(layoutType) {
            return _c(
              "b-tab",
              {
                key: layoutType,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "apps.configurator.home-layout.type-" +
                                    layoutType
                                )
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm.homeLayoutData[layoutType].data !== null
                  ? _c("div", { staticClass: "px-8" }, [
                      _c(
                        "div",
                        { staticClass: "mb-5" },
                        [
                          _c("DynamicForm", {
                            attrs: {
                              isReadOnly: _vm.isReadOnly,
                              value: _vm.homeLayoutData[layoutType].formData
                            },
                            on: {
                              input: function($event) {
                                return _vm.getDinamicInputs(layoutType, $event)
                              },
                              "check-validation": function($event) {
                                return _vm.validateTypeErrors(
                                  layoutType,
                                  $event
                                )
                              }
                            }
                          }),
                          _c(
                            "b-alert",
                            {
                              staticClass: "my-5 rounded-lg",
                              attrs: {
                                show: _vm.homeLayoutData.home.hasErrors,
                                variant: "danger"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("apps.configurator.home-layout.errors")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      !_vm.isReadOnly
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "action-item-button-container buttons pb-5"
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    color: "primary",
                                    type: "border rounded-lg mr-3"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.cancelHomeLayout(layoutType)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "apps.configurator.home-layout.cancel"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "xs:w-full sm:w-auto rounded-lg",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.saveHomeLayout(layoutType)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "apps.configurator.home-layout.save"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }