import axios from '@/axios.js'

export default {
    getAllTemplates: async () => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/home-layouts-templates`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
